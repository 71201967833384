import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, exhaustMap, map, take, tap } from "rxjs/operators";
import { BehaviorSubject, Subject, throwError } from "rxjs";
import { User } from "../interfaces/user.interface";
import { AuthService } from "./auth.service";
import { API_URLS } from "../utilities/service-config";

@Injectable({ providedIn: "root" })
export class UsersService {
  private users: User[];
  userToEdit: BehaviorSubject<User>;
  editMode: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.userToEdit = new BehaviorSubject({
      firstName: "",
      lastName: "",
      email: "",
      department: "",
      position: "",
      password: "",
    });
    this.editMode = new BehaviorSubject(false);
  }

  fetchUsers() {
    return this.http.get<User[]>(API_URLS.USERS).pipe(
      map((users) => {
        return users;
      })
    );
  }

  addUser(user) {
    return this.http.post(API_URLS.ADD_USER, user).pipe(
      catchError((errorRes) => {
        let errorMessage = "An unknown error occured";
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
        switch (errorRes.error.error.message) {
          case "Unauthorized":
            errorMessage = "You are not authorized to add users";
        }

        return throwError(errorMessage);
      }),
      tap((resData) => {})
    );
  }

  deleteUser(userId: number) {
    return this.http.delete(API_URLS.DELETE_USER(userId)).pipe(
      catchError((errorRes) => {
        let errorMessage = "An unknown error occured";
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
        switch (errorRes.error.error.message) {
          case "Unauthorized":
            errorMessage = "You are not authorized to delete users";
        }

        return throwError(errorMessage);
      }),
      tap((resData) => {})
    );
  }

  updateUser(userId: string, user) {
    return this.http.put(API_URLS.UPDATE_USER(userId), user).pipe(
      catchError((errorRes) => {
        let errorMessage = "An unknown error occured";
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
        switch (errorRes.error.error.message) {
          case "Unauthorized":
            errorMessage = "You are not authorized to update users";
        }

        return throwError(errorMessage);
      }),
      tap((resData) => {})
    );
  }

  nextToEditUser(editMode: boolean, user?: User) {
    this.editMode.next(editMode);
    if (editMode) {
      this.userToEdit.next(user);
    } else {
      const newUser: User = {
        firstName: "",
        lastName: "",
        email: "",
        department: "",
        position: "",
        password: "",
      };
      this.userToEdit.next(newUser);
    }
  }
}
