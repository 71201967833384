import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { User } from "src/app/shared/interfaces/user.interface";
import { UsersService } from "src/app/shared/services/users.service";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.page.html",
  styleUrls: ["./user-details.page.scss"],
})
export class UserDetailsPage implements OnInit {
  editMode: boolean = false;
  error: string = "";
  userToEdit: User = {
    firstName: "",
    lastName: "",
    email: "",
    department: "",
    position: "",
    password: "",
  };
  constructor(private userService: UsersService) {}

  ngOnInit() {
    this.userService.userToEdit.subscribe((user) => {
      this.userToEdit = user;
    });
    this.userService.editMode.subscribe((mode) => {
      this.editMode = mode;
    });
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const user = {
      firstName: form.value.fname,
      lastName: form.value.lname,
      email: form.value.email,
      department: form.value.department,
      position: form.value.position,
      password: form.value.password,
    };
    if (!this.editMode) {
      this.userService.addUser(user).subscribe(
        (response) => {},
        (errorMessage) => {
          this.error = errorMessage;
        }
      );
    } else {
      this.userService.updateUser(this.userToEdit._id, user).subscribe(
        (response) => {},
        (errorMessage) => {
          this.error = errorMessage;
        }
      );
    }
    form.reset();
  }
}
