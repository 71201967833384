import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "user-details",
    loadChildren: () =>
      import("./pages/user-details/user-details.module").then(
        (m) => m.UserDetailsPageModule
      ),
  },
  {
    path: "add-edit-user",
    loadChildren: () =>
      import("./pages/add-edit-user/add-edit-user.module").then(
        (m) => m.AddEditUserPageModule
      ),
  },
  // {
  //   path: "charts",
  //   loadChildren: () =>
  //     import("./pages/charts/charts.module").then((m) => m.ChartsPageModule),
  // },
  {
    path: "devices",
    loadChildren: () =>
      import("./pages/devices/devices.module").then((m) => m.DevicesPageModule),
  },
  {
    path: "device/:id",
    loadChildren: () =>
      import("./pages/device/device.module").then((m) => m.DevicePageModule),
  },
  // {
  //   path: "details",
  //   loadChildren: () =>
  //     import("./pages/device-details/device-details.module").then(
  //       (m) => m.DeviceDetailsPageModule
  //     ),
  // },
  {
    path: "add-device",
    loadChildren: () =>
      import("./pages/add-device/add-device.module").then(
        (m) => m.AddDevicePageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
