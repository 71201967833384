import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js
  //If env.js cannot be loaded,
  //the application will fall back to the default values defined in the EnvService class.
  public apiUri = "";
  public websocketUri = "";
  public mqttUri = "";

  constructor() {}
}
