import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { API_URLS } from "../utilities/service-config";
import { Message } from "../interfaces/message.interface";
import { BitvoxAssistant } from "bitvox-assistant";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";

export interface WsJsonPlayload {
  event: string;
  recipientIdentity: string;
  json: {
    data1: string;
    data2: string;
  };
}
@Injectable({ providedIn: "root" })
export class AssistantService {
  public messages: Subject<Message>;
  public assistantIdentity: string;
  payload: BehaviorSubject<{ isFinal: boolean; message: string; id: string }>;
  payloadFromServer: BehaviorSubject<{
    createdAt: string;
    message: string;
    senderIdentity: string;
    recipientIdentity: string;
    id: string;
  }>;
  textPayload: BehaviorSubject<boolean>;
  allowStreaming: BehaviorSubject<boolean>;
  isStreaming: BehaviorSubject<boolean>;
  updatedMessage: BehaviorSubject<{}>;
  wsConnected: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private ngZone: NgZone) {
    this.payload = new BehaviorSubject({ isFinal: false, message: "", id: "" });
    this.payloadFromServer = new BehaviorSubject({
      createdAt: "",
      message: "",
      senderIdentity: "",
      recipientIdentity: "",
      id: "",
    });
    this.textPayload = new BehaviorSubject(false);
    this.allowStreaming = new BehaviorSubject(false);
    this.isStreaming = new BehaviorSubject(false);
    this.updatedMessage = new BehaviorSubject({});
    this.wsConnected = new BehaviorSubject(false);
  }

  async loadAssistantIdentity() {
    const getAssistantPromise = new Promise((resolve) => {
      this.getAssistantIdentity().subscribe((assistantList: any[]) => {
        resolve(assistantList);
      });
    });
    return await getAssistantPromise;
  }

  fetchMessages(parameters) {
    return this.http.get<Message[]>(API_URLS.MESSAGES(parameters)).pipe(
      map((messages) => {
        return messages;
      })
    );
  }

  getAssistantIdentity() {
    return this.http.get(API_URLS.GET_ASSISTANT_IDENTITY).pipe(
      map((assistants) => {
        return assistants;
      })
    );
  }

  async openWsConnection() {
    const assistantList = await this.loadAssistantIdentity();
    this.assistantIdentity = "assistant/" + assistantList[0]._id;
    const accessToken = JSON.parse(localStorage.getItem("token"))._access_token;
    await BitvoxAssistant.setConfig({
      restApiUri: environment.apiUri,
      websocketUri: environment.websocketUri,
      assistantIdentity: this.assistantIdentity,
    });
    await BitvoxAssistant.setAccessToken({
      accessToken: accessToken,
    });
    await BitvoxAssistant.connectWs();
    await BitvoxAssistant.addListener(
      "message-audiostream-transcription",
      (payload: any) => {
        this.ngZone.run(() => {
          // console.log("event message-audiostream-transcription was fired !");
          // console.log(payload);
          const data = {
            isFinal: payload.isFinal,
            message: payload.message.messageText,
            id: payload.message._id,
          };
          this.payload.next(data);
        });
      }
    );
    await BitvoxAssistant.addListener(
      "message-text-response",
      (payload: any) => {}
    );
    await BitvoxAssistant.addListener(
      "message-audiostream-request",
      (payload: any) => {
        this.ngZone.run(() => {
          // console.log("event message-audiostream-request was fired !");
          const data = {
            message: payload.message.messageText,
            createdAt: payload.message.createdAt,
            senderIdentity: payload.message.recipientIdentity,
            recipientIdentity: payload.message.senderIdentity,
            id: payload.message._id,
          };
          this.payloadFromServer.next(data);
        });
      }
    );
    await BitvoxAssistant.addListener("message-update", (payload: any) => {
      this.ngZone.run(() => {
        console.log("event message-update was fired !");
        this.updatedMessage.next(payload.message);
      });
    });
    await BitvoxAssistant.addListener("kwd-detection", (payload: any) => {
      this.ngZone.run(() => {
        console.log("got kwd-detection event");
        this.allowStreaming.next(payload.allowStreaming);
        this.isStreaming.next(payload.allowStreaming);
      });
    });
    // indicating that websockt is connected
    this.wsConnected.next(true);
  }

  async requestMessageAudiostream(
    singleUtterance: boolean,
    assistantIdentity: string
  ) {
    await BitvoxAssistant.requestMessageAudiostream({
      singleUtterance: singleUtterance,
      assistantIdentity: assistantIdentity,
    }).then((payload) => {
      console.log(payload.allowStreaming);
      this.allowStreaming.next(payload.allowStreaming);
    });
  }

  async stopStream() {
    await BitvoxAssistant.completeMessageAudiostream();
    this.isStreaming.next(false);
  }

  async audioStreamTranscription() {
    // this.assistantService.audioStreamTranscription().then((payload) => {
    //   this.message = payload;
    //   console.log(this.message);
    // });
  }

  // async sendJsonWs(wsJsonPlayload: WsJsonPlayload) {
  //   await BitvoxAssistant.sendJsonWs({
  //     jsonPayload: JSON.stringify(wsJsonPlayload),
  //   });
  // }

  async sendMessageText(text: string) {
    // console.log("assistant-service sendMessageText called");
    await BitvoxAssistant.sendMessageText({
      text: text,
    }).then((payload) => {
      this.textPayload.next(payload.success);
    });
  }

  async startAudioRecording() {
    await BitvoxAssistant.startAudioRecording();
  }

  async stopAudioRecording() {
    setTimeout(() => {
      BitvoxAssistant.stopAudioRecording();
    }, 5000);
  }

  async disconnectWs() {
    await BitvoxAssistant.disconnectWs();
  }

  async initKwd(
    kwdKeyword: string,
    kwdEngine: string,
    kwdDetectionThreshold: number
  ) {
    await BitvoxAssistant.initKwd({
      kwdDetectionThreshold: kwdDetectionThreshold,
      kwdEngine: kwdEngine,
      kwdKeyword: kwdKeyword,
    });
  }
  async startKwd() {
    await BitvoxAssistant.startKwd();
  }
  async stopKwd() {
    await BitvoxAssistant.startKwd();
  }
}
