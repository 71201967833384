import { Component, OnInit, ViewChild } from "@angular/core";
import { AssistantService } from "../../services/assistant.service";
import { IonInfiniteScroll, IonContent } from "@ionic/angular";

import * as moment from "moment";
import { AuthService } from "../../services/auth.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content;
  public chatForm: FormGroup;
  textMessage: string = "";
  sender: string = "";
  messagesList = [];
  parameters = {
    identity1: "",
    identity2: "",
    amount: 15,
  };
  hide: boolean = true;
  //text to add in the bubble
  message: string;

  isFinal: boolean;
  serverPayload: {
    message: string;
    createdAt: string;
    senderIdentity: string;
    recipientIdentity: string;
  };
  isStreaming: boolean = false;
  allowStreaming: boolean;
  isReleased: boolean = true;
  scrollElement: any;

  constructor(
    private assistantService: AssistantService,
    private authService: AuthService
  ) {}

  handleShortPress(e) {
    console.log("this is a short press");
    if (this.hide) {
      this.isStreaming = true;
      this.isReleased = false;
      this.assistantService.requestMessageAudiostream(
        true,
        this.assistantService.assistantIdentity
      );
    } else {
      this.sendTextMessage();
    }
  }
  handleLongPress(e) {
    console.log("this is a long press");
    if (this.hide) {
      this.isStreaming = true;
      this.isReleased = false;
      this.assistantService.requestMessageAudiostream(
        false,
        this.assistantService.assistantIdentity
      );
    }
  }
  handleLongPressRelease(e) {
    console.log("long press released");
    if (this.hide) {
      this.assistantService.stopStream();
      this.isStreaming = false;
      this.isReleased = true;
      // this.assistantService.stopStream();
      this.assistantService.allowStreaming.next(false);
    }
  }
  async ngAfterViewInit() {
    this.scrollElement = await this.content.getScrollElement();
    this.scrollElement.scrollTop = this.scrollElement.offsetHeight;
  }
  async ngOnInit() {
    this.assistantService.wsConnected.subscribe((res) => {
      if (res) {
        this.loadAssistant();
      }
    });
  }
  async loadAssistant() {
    const user = this.authService.getUser();
    this.sender = localStorage.getItem("identity");
    this.parameters.identity1 = localStorage.getItem("identity");
    this.parameters.identity2 = this.assistantService.assistantIdentity;
    this.loadMessages();
    this.subscribeToTranscription();
    this.subscribeToServerResponse();
    this.subscribeToTextMessageResponse();
    this.assistantService.allowStreaming.subscribe((res) => {
      this.allowStreaming = res;
      if (this.allowStreaming) {
        this.hide = true;
        this.textMessage = "";
      }
    });
    this.assistantService.isStreaming.subscribe((res) => {
      this.isStreaming = res;
      console.log(res);
    });

    this.assistantService.updatedMessage.subscribe((message) => {
      if (message.hasOwnProperty("messageText")) {
        this.updateMessage(message);
      }
    });
  }
  updateMessage(message) {
    const msg = this.messagesList.find((item) => item.id == message._id);
    msg.messageText = message.messageText;
  }
  subscribeToTextMessageResponse() {
    this.assistantService.textPayload.subscribe((payload) => {
      if (payload) {
        this.messagesList.push({
          messageText: this.textMessage,
          createdAt: moment(new Date()).format("DD.MM.YYYY HH:mm"),
          recipientIdentity: this.parameters.identity2,
          senderIdentity: this.parameters.identity1,
        });
        this.textMessage = "";
      }
    });
  }
  subscribeToTranscription() {
    this.assistantService.payload.subscribe((payload) => {
      this.message = payload.message;
      this.isFinal = payload.isFinal;
      if (this.isFinal) {
        this.messagesList.push({
          messageText: this.message,
          createdAt: moment(new Date()).format("DD.MM.YYYY HH:mm"),
          recipientIdentity: this.parameters.identity2,
          senderIdentity: this.parameters.identity1,
          id: payload.id,
        });
        this.message = "";
        // this.assistantService.stopStream();
        this.assistantService.allowStreaming.next(false);
      }
    });
  }

  subscribeToServerResponse() {
    this.assistantService.payloadFromServer.subscribe((payload) => {
      if (payload.createdAt) {
        this.messagesList.push({
          messageText: payload.message,
          createdAt: moment(payload.createdAt).format("DD.MM.YYYY HH:mm"),
          recipientIdentity: payload.senderIdentity,
          senderIdentity: payload.recipientIdentity,
          id: payload.id,
        });
        this.scrollToBottom();
      }
    });
  }
  async scrollToBottom() {
    if (!this.scrollElement) {
      this.scrollElement = await this.content.getScrollElement();
    }
    this.scrollElement.scrollTop = this.scrollElement.scrollHeight;
  }

  async loadMessages(event?) {
    this.assistantService.fetchMessages(this.parameters).subscribe(
      (res) => {
        this.messagesList = res.reverse();
        this.messagesList = this.messagesList.filter(
          (message) => message.messageText != ""
        );
        this.messagesList = this.messagesList.map((message) => {
          return {
            messageText: message.messageText,
            createdAt: moment(message.createdAt).format("DD.MM.YYYY HH:mm"),
            recipientIdentity: message.recipientIdentity,
            senderIdentity: message.senderIdentity,
          };
        });
        if (event) {
          event.target.complete();
          this.getScrollElement();
        }
      },
      (error) => {}
    );
  }
  async getScrollElement() {
    if (!this.scrollElement) {
      this.scrollElement = await this.content.getScrollElement();
    }
    this.scrollElement.scroll({
      top: "10%",
      behaviour: "smooth",
    });
  }
  showText() {
    this.hide = !this.hide;
  }

  loadMore(event) {
    this.parameters.amount += 15;
    this.loadMessages(event);
    const currentLength = this.messagesList.length;
    // if (this.parameters.amount >= 22) {
    //   event.target.disabled = true;
    // }
  }

  sendTextMessage() {
    console.log("text message sent from client");
    // this.assistantService.sendMessageText(this.textMessage).then(() => { });
    this.assistantService.sendMessageText(this.textMessage);
  }
}
