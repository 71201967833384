import {
  Directive,
  HostListener,
  EventEmitter,
  Output,
  Input,
  OnInit,
} from "@angular/core";
import { Observable, Subject, interval, race, Subscription } from "rxjs";
// import { takeUntil, tap, filter } from "rxjs/operators";
import {
  map,
  filter,
  takeUntil,
  first,
  last,
  skipUntil,
  tap,
  takeWhile,
  mapTo,
  takeLast,
  take,
} from "rxjs/operators";

@Directive({
  selector: "[holdable]",
})
export class HoldableDirective implements OnInit {
  @Output() shortPress: EventEmitter<string> = new EventEmitter();
  @Output() longPress: EventEmitter<string> = new EventEmitter();
  @Output() longPressRelease: EventEmitter<string> = new EventEmitter();
  @Input() longPressThreshold: number;
  state: Subject<string> = new Subject();
  releaseObservable: Observable<any>;
  longPressInterval: number = 100;
  holdTime: number = 0;

  constructor() {
    this.releaseObservable = this.state.pipe(filter((v) => v === "cancel"));
    this.releaseObservable.subscribe(() => {
      console.log("stopped hold");
      console.log(this.holdTime);
      if (this.holdTime < this.longPressThreshold) {
        this.shortPress.emit();
      } else {
        this.longPressRelease.emit();
        this.holdTime = 0;
      }
    });
  }

  ngOnInit() {}
  @HostListener("mouseup", ["$event"])
  @HostListener("mouseLeave", ["$event"])
  onExit() {
    this.state.next("cancel");
  }
  @HostListener("mousedown", ["$event"])
  onHold() {
    this.state.next("start");

    interval(this.longPressInterval)
      .pipe(
        takeUntil(this.releaseObservable),
        map((v) => v * 100),
        tap((v) => {
          this.holdTime = v;
          console.log("hold time is: " + v);
        }),
        filter((x) => x >= this.longPressThreshold),
        take(1)
      )
      .subscribe(() => {
        this.longPress.emit();
      });
  }

  // @HostListener("mousedown", ["$event"])
  // onHold() {
  //   //logs
  //   // console.log("started hold");
  //   // console.log("thresold" + this.longPressThreshold);
  //   //set state
  //   // this.state.next("start");

  //   //observables
  //   let holdInterval = interval(this.longPressInterval).pipe(
  //     map((x) => x * this.longPressInterval),
  //     takeUntil(this.releaseObservable)
  //   );
  //   // this observable is emitting an event if the longPressThreshold is exceeded
  //   let shortPress = holdInterval.pipe(
  //     filter((x) => x < this.longPressThreshold),
  //     last()
  //   );
  //   let longPress = holdInterval.pipe(
  //     filter((x) => x >= this.longPressThreshold),
  //     take(1)
  //   );
  //   let shortPressRelease = holdInterval.pipe(last(), skipUntil(shortPress));
  //   let longPressRelease = holdInterval.pipe(last(), skipUntil(longPress));

  //   //Subscription
  //   holdInterval.subscribe((x) => {
  //     console.log(x);
  //   });
  //   let short: Subscription;
  //   let shortRelease: Subscription;
  //   short = shortPress.subscribe((x) => {
  //     // console.log("shortpress");
  //   });
  //   shortRelease = shortPressRelease.subscribe(() => {
  //     // console.log("shortPressReleased");
  //     this.shortPress.emit();
  //   });
  //   longPress.subscribe(() => {
  //     short.unsubscribe();
  //     shortRelease.unsubscribe();
  //     this.longPress.emit();
  //     // console.log("longPress");
  //   });
  //   longPressRelease.subscribe(() => {
  //     // console.log("longPressReleased");
  //     this.longPressRelease.emit();
  //   });
  // }
}
