import { Injectable } from "@angular/core";
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class TestMqttService {
  private endpoint: string;
  // deviceId: BehaviorSubject<string>;

  constructor(private _mqttService: MqttService) {
    // this.deviceId = new BehaviorSubject("001");
    // this.endpoint = `events/devices/${this.deviceId.value}/status/#`;
  }

  setEndpoint(deviceId: string) {
    this.endpoint = `event/device/${deviceId}/status/#`;
  }

  topic(): Observable<IMqttMessage> {
    let topicName = `${this.endpoint}`;
    console.log(topicName);
    return this._mqttService.observe(topicName);
  }
}
