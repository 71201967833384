import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/shared/services/auth.service";
import { UsersService } from "src/app/shared/services/users.service";
import { User } from "src/app/shared/interfaces/user.interface";

@Component({
  selector: "app-users",
  templateUrl: "./users.page.html",
  styleUrls: ["./users.page.scss"],
})
export class UsersPage implements OnInit {
  userSub: Subscription;
  usersList: User[] = [];
  page: number = 0;
  usersCount: number = 8;
  totalPages: number = 10;
  bulkDelete: boolean = false;
  bulkDeleteItems = {};

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userSub = this.authService.user.subscribe((user) => {
      this.usersService.fetchUsers().subscribe(
        (res) => {
          this.usersList = res;
        },
        (error) => {}
      );
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  toggleBulkEdit() {}

  deleteAll() {}

  remove(userId: number, index: number) {
    this.usersService.deleteUser(userId).subscribe(
      (resData) => {
        this.usersList.splice(index, 1);
      },
      (error) => {}
    );
  }

  edit(index: number, user: User) {
    this.usersService.nextToEditUser(true, user);
  }

  add() {
    this.usersService.nextToEditUser(false);
  }
}
