import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { AuthenticatedUser } from "../../pages/login/authenticatedUser.model";
import { Router } from "@angular/router";
import { API_URLS } from "../utilities/service-config";
import { stringify } from "@angular/compiler/src/util";

interface AuthResponseData {
  access_token: string;
  identity: string;
}
@Injectable({ providedIn: "root" })
export class AuthService {
  //store authenticated user as a subject
  user = new BehaviorSubject<AuthenticatedUser>(null);
  class = new BehaviorSubject<string>("ion-hide-lg-up");
  accessToken = new BehaviorSubject<string>("");
  constructor(private http: HttpClient, private router: Router) {}

  signUp() {}

  login(username: string, password: string) {
    return this.http
      .post<AuthResponseData>(API_URLS.LOGIN, {
        username: username,
        password: password,
      })
      .pipe(
        catchError((errorRes) => {
          let errorMessage = "An unknown error occured";
          if (!errorRes.error || !errorRes.error.error) {
            return throwError(errorMessage);
          }
          switch (errorRes.error.error.message) {
            case "Unauthorized":
              errorMessage =
                "Login/Email combination is not correct, please try again.";
          }

          return throwError(errorMessage);
        }),
        tap((resData) => {
          const user = new AuthenticatedUser(resData.access_token);
          this.user.next(user);
          localStorage.setItem("token", JSON.stringify(user));
          localStorage.setItem("identity", resData.identity);
          this.accessToken.next(user.token);
        })
      );
  }

  autoLogin() {
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      return;
    }
    const loadedUser = new AuthenticatedUser(token._access_token);
    this.user.next(loadedUser);
  }

  logout() {
    this.user.next(null);
    this.router.navigate(["./login"]);
    localStorage.removeItem("token");
    localStorage.removeItem("userFullName");
  }

  getUser() {
    const user = {
      email: JSON.parse(localStorage.getItem("email")),
      password: JSON.parse(localStorage.getItem("password")),
    };
    if (!user) {
      return;
    }
    return user;
  }

  setClass(className: string) {
    const newClass = className;
    this.class.next(newClass);
  }
}
