import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class SplitPaneService {
  hideToggleButton: BehaviorSubject<boolean>;
  hide: boolean = true;

  constructor() {
    this.hideToggleButton = new BehaviorSubject(this.hide);
  }

  nextHideToggleButton() {
    this.hide = !this.hide;
    this.hideToggleButton.next(this.hide);
  }
}
