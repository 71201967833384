const base = window["__env"]["apiUri"] || "";
export const API_URLS = {
  /* Authentication */
  LOGIN: `${base}/auth/login`,
  /*Assistant*/
  GET_ASSISTANT_IDENTITY: `${base}/assistant`,
  /*users*/
  USERS: `${base}/user`,
  ADD_USER: `${base}/user`,
  GET_USER_IDENTITY: (userEmail) => `${base}/user/getIdentity/${userEmail}`,
  DELETE_USER: (userId) => `${base}/user/${userId}`,
  UPDATE_USER: (userId) => `${base}/user/${userId}`,
  MESSAGES: (parameters) =>
    `${base}/message/?identity1=${parameters.identity1}&identity2=${parameters.identity2}&amount=${parameters.amount}`,
  DEVICES: `${base}/device`,
  DEVICE: (deviceId) => `${base}/device/${deviceId}`,
  EVENTS: (parameters) =>
    `${base}/event/?fromDate=${parameters.fromDate}&toDate=${
      parameters.toDate ? parameters.toDate : ""
    }&eventType=${parameters.eventType}&subjectType=${
      parameters.subjectType
    }&senderUuid=${parameters.senderUuid}`,
};
