import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { UsersPageRoutingModule } from "./users-routing.module";

import { UsersPage } from "./users.page";
import { SharedModule } from "src/app/shared/modules/shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UsersPageRoutingModule,
    SharedModule,
  ],
  declarations: [UsersPage],
})
export class UsersPageModule {}
