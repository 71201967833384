import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "../../components/header/header.component";
import { IonicModule } from "@ionic/angular";
import { ChatComponent } from "../../components/chat/chat.component";
import { FormsModule } from "@angular/forms";
import { HoldableDirective } from "../../directives/holdable.directive";

@NgModule({
  declarations: [HeaderComponent, ChatComponent, HoldableDirective],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [HeaderComponent, ChatComponent],
})
export class SharedModule {}
